import helpers from './../sass/helpers.module.scss';

export const css = {
  spaceBottomL: helpers.marginBottomL,
  spaceBottomM: helpers.marginBottomM,
  spaceBottomS: helpers.marginBottomS,
  spaceL: helpers.marginL,
  spaceLeftL: helpers.marginLeftL,
  spaceLeftM: helpers.marginLeftM,
  spaceLeftS: helpers.marginLeftS,
  spaceM: helpers.marginM,
  spaceRightL: helpers.marginRightL,
  spaceRightM: helpers.marginRightM,
  spaceRightS: helpers.marginRightS,
  spaceS: helpers.marginS,
  spaceTopL: helpers.marginTopL,
  spaceTopM: helpers.marginTopM,
  spaceTopS: helpers.marginTopS,

  fontSmall: helpers.fontSmall,
  fontLarge: helpers.fontLarge,
  fontBold: helpers.fontBold,
  fontNormal: helpers.fontNormal,
  fontSans: helpers.fontSans,

  fontColorPrimary: helpers.fontColorPrimary,
  fontColorSecondary: helpers.fontColorSecondary,
  fontColorSuccess: helpers.fontColorSuccess,
  fontColorWarning: helpers.fontColorWarning,
  fontColorError: helpers.fontColorError,
  fontColorErrorLight: helpers.fontColorErrorLight,
  fontColorBlueLink: helpers.fontColorBlueLink,
  fontColorGrey: helpers.fontColorGrey,
  fontColorGrey2: helpers.fontColorGrey2,
  fontColorGrey3: helpers.fontColorGrey3,
  fontColorGrey4: helpers.fontColorGrey4,
  fontColorGrey5: helpers.fontColorGrey5,
  fontColorGrey6: helpers.fontColorGrey6,

  showOnDesktop: helpers.showOnDesktop,
  showOnTablet: helpers.showOnTablet,
  showOnMobile: helpers.showOnMobile,
  hideOnDesktop: helpers.hideOnDesktop,
  hideOnTablet: helpers.hideOnTablet,
  hideOnMobile: helpers.hideOnMobile,

  preWrap: helpers.preWrap,
};
